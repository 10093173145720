import { v4 } from "uuid";
import axios from "axios";
import parseProxy from "@devhigley/parse-proxy";

function getProxyValue(): string | undefined {
  // TODO: ugly hack to get mocks-server to work locally and to stop production outage...
  if (
    typeof window == "undefined" &&
    (!!process?.env?.MOCKS_SERVER_ENABLED || !!process?.env?.MOCKS_SERVER_DIRECTORY)
  ) {
    let proxy = process?.env?.https_proxy || process?.env?.http_proxy;
    if (proxy && !proxy.startsWith("http")) {
      proxy = `http://${proxy}`;
    }

    return proxy;
  }
  return undefined;
}

const proxyValue = getProxyValue();
const proxy = proxyValue ? parseProxy(proxyValue)[0] : undefined;

class HttpClient {
  axios = axios.create({
    proxy,
  });

  constructor() {
    this.setInterceptors();
  }

  setInterceptors() {
    this.axios.interceptors.request.use((req) => {
      // Important: request interceptors **must** return the request.
      if (req?.headers) {
        req.headers["Transaction-Id"] = this.getUUID();
        req.headers["Session-Id"] = this.getUUID();
        req.withCredentials = true;
      }
      return req;
    });
  }

  getUUID() {
    const id = v4();
    return id;
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const httpClient = new HttpClient();
const http = httpClient.axios;

export default http;
